<template>
    <div class="container text-center col-lg-9">
        <div class="row mb-2">
            <h1 class="fw-light">Tesi di <span class="fw-bold">{{ name }}</span></h1>
        </div>

        <div class="row my-3 mx-0">
            <div class="col-4 col-lg-3 py-1 px-0 rounded-start bgAlma">
                <h3 class="fw-bold mb-0 text-center text-white">Media totale</h3>
            </div>
            <div class="col-2 col-lg-3 py-1 pe-0 rounded-end align-content-center bgAlma">
                <h4 class="fw-bold mb-0 text-white">{{ mediaTesi }}</h4>
            </div>
            <div class="col-6"></div>
        </div>

        <!-- DOCENTE LOGGATO -->
        <div class="mt-3 border rounded py-2 px-3">
            <div class="row mb-1">
                <div class="col">
                    <h4 class="text-start">{{ $store.state.userprofile.Name }}</h4>
                </div>
            </div>

            <div class="row mb-3" v-for="giudizio in mGiudizi[$store.state.userprofile.Id]" v-bind:key="giudizio.Id">
                <div class="col-4 col-lg-3 align-content-center text-start fw-normal text-uppercase">{{ giudizio.Tipo__c }}</div>
                <div class="col-2 col-lg-3 fs-5 pe-0 align-content-center">
                        <span :class="giudizio.Voto__c < 18 ? 'fw-bold bg-danger bg-opacity-25 px-3':''">{{ giudizio.Voto__c }}</span>
                        <span class='bi ps-0 ps-md-2 position-absolute' :class="{ 'bi-pencil-fill': fase && fase == giudizio.Tipo__c, 'bi-eye-fill' : !fase }" @click.prevent="$emit('mostraTesi')"></span>
                </div>
                <div class="col-6">
                    <div class="accordion-item">
                        <h2 class='accordion-header'>
                            <button :id="giudizio.buttonGiudGiudizio" type="button" 
                            :class="!giudizio.Giudizio__c ? 'accordion-button collapsed bg-secondary bg-opacity-25 rounded-0':'accordion-button collapsed bgAlma rounded-1 text-white'"
                            @click="displayAccordionBody(giudizio.buttonGiudGiudizio,giudizio.giudGiudizio)"
                            :disabled="!giudizio.Giudizio__c ? true : false">
                                Giudizio
                            </button>
                        </h2>
                        <div :id="giudizio.giudGiudizio" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="text-start mb-0">{{ giudizio.Giudizio__c }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- TUTTI GLI ALTRI DOCENTI -->
        <span v-for="(giudizi, docenteId) in mGiudizi" v-bind:key="docenteId">
            <div v-if="docenteId != $store.state.userprofile.Id" class="mt-3 border rounded py-2 px-3">
                <div class="row mb-1">
                    <div class="col">
                        <h4 class="text-start">{{ docenti[docenteId] }}</h4>
                    </div>
                    <!-- <div class="col">
                        <h5>
                            <span :class="giudizio.Voto__c < 18 ? 'fw-bold bg-danger bg-opacity-25 px-4':''">{{ giudizio.Voto__c }}</span>
                            <span v-if="giudizio.Docente__c == $store.state.userprofile.Id && letturaTesi" class='bi bi-pencil-fill ps-4 position-absolute' @click.prevent="$emit('mostraTesi')"></span>
                        </h5>
                    </div>
                    <div class="col">
                        <h5>
                            <span :class="giudizio.Voto__c < 18 ? 'fw-bold bg-danger bg-opacity-25 px-4':''">trova campo</span>
                            <span v-if="giudizio.Docente__c == $store.state.userprofile.Id && !letturaTesi" class='bi bi-pencil-fill ps-4 position-absolute' @click.prevent="$emit('mostraTesi')"></span>
                        </h5>
                    </div> -->
                </div>

                <div class="row mb-3" v-for="giudizio in giudizi" v-bind:key="giudizio.Id">
                    <div class="col-4 col-lg-3 align-content-center text-start fw-normal text-uppercase">{{ giudizio.Tipo__c }}</div>
                    <div class="col-2 col-lg-3 fs-5 pe-0 align-content-center">
                            <span :class="giudizio.Voto__c < 18 ? 'fw-bold bg-danger bg-opacity-25 px-3':''">{{ giudizio.Voto__c }}</span>
                    </div>
                    <div class="col-6">
                        <div class="accordion-item">
                            <h2 class='accordion-header'>
                                <button :id="giudizio.buttonGiudGiudizio" type="button" 
                                :class="!giudizio.Giudizio__c ? 'accordion-button collapsed bg-secondary bg-opacity-25 rounded-0':'accordion-button collapsed bgAlma rounded-1 text-white'"
                                @click="displayAccordionBody(giudizio.buttonGiudGiudizio,giudizio.giudGiudizio)"
                                :disabled="!giudizio.Giudizio__c ? true : false">
                                    Giudizio
                                </button>
                            </h2>
                            <div :id="giudizio.giudGiudizio" class="accordion-collapse collapse">
                                <div class="accordion-body">
                                    <p class="text-start mb-0">{{ giudizio.Giudizio__c }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <!-- <div class="row mb-4">
                <div class="col"></div>
                <div class="col">
                    <div class="accordion">
                <div class="accordion-item">
                    <h2 class='accordion-header'>
                        <button :id="giudizio.buttonNoteGiudizio" type="button" 
                        :class="!giudizio.Note__c ? 'accordion-button collapsed bg-secondary bg-opacity-25 rounded-0':'accordion-button collapsed'"
                        @click="displayAccordionBody(giudizio.buttonNoteGiudizio, giudizio.noteGiudizio)"
                        :disabled="!giudizio.Note__c ? true : false">
                            Commento
                        </button>
                    </h2>
                    <div :id="giudizio.noteGiudizio" class="accordion-collapse collapse">
                        <div class="accordion-body">
                            <p class="text-start mb-0">{{ giudizio.Note__c }}</p>
                        </div>
                    </div>
                </div>
                        <div class="accordion-item">
                            <h2 class='accordion-header'>
                                <button :id="giudizio.buttonGiudGiudizio" type="button" 
                                :class="!giudizio.Giudizio__c ? 'accordion-button collapsed bg-secondary bg-opacity-25 rounded-0':'accordion-button collapsed'"
                                @click="displayAccordionBody(giudizio.buttonGiudGiudizio,giudizio.giudGiudizio)"
                                :disabled="!giudizio.Giudizio__c ? true : false">
                                    Giudizio
                                </button>
                            </h2>
                            <div :id="giudizio.giudGiudizio" class="accordion-collapse collapse">
                                <div class="accordion-body">
                                    <p class="text-start mb-0">{{ giudizio.Giudizio__c }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="accordion-item">
                        <h2 class='accordion-header'>
                            <button :id="giudizio.buttonGiudGiudizio" type="button" 
                            :class="!giudizio.Giudizio__c ? 'accordion-button collapsed bg-secondary bg-opacity-25 rounded-0':'accordion-button collapsed'"
                            @click="displayAccordionBody(giudizio.buttonGiudGiudizio,giudizio.giudGiudizio)"
                            :disabled="!giudizio.Giudizio__c ? true : false">
                                Giudizio
                            </button>
                        </h2>
                        <div :id="giudizio.giudGiudizio" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="text-start mb-0">{{ giudizio.Giudizio__c }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            </div>
        </span>
    </div>

</template>

<script>
export default {
    name: 'vediGiudizi',
    components: {},
    props: {
        name: String,
        mGiudizi: Object,
        mediaTesi: Number,
        docenti: Object,
        fase: String,
    },

    data() {
        return {}
    },

    mounted () {},

    methods: {
        displayAccordionBody(button, body) {
            const b = document.querySelector('#'+button);
            if(b.className.indexOf('collapsed') == -1) b.className += ' collapsed'
            else b.className = b.className.replace('collapsed', '')

            const collapseOne = document.querySelector('#'+body);
            if(collapseOne.className.indexOf('show') == -1) collapseOne.className += ' show'
            else collapseOne.className = collapseOne.className.replace('show', '')
        }
    }
}
</script>